import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { ContactWhereInput } from '@lgg/isomorphic/types/__generated__/graphql';
import { getFiltersCount } from 'src/components/filters/react-filters';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastWizardStepProps } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepSectionDescription,
  WizardStepSectionDivider,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import { useBroadcastAvailableContactsForChannelSms } from 'src/components/pages/broadcast/hooks/use-broadcast-available-contacts-for-channel-sms';
import {
  ContactFilters,
  ContactFiltersFormValues,
} from 'src/components/pages/contacts/components/contact-filters';
import { useConvertFilterParamsToWhereInput } from 'src/components/pages/contacts/contact-queries';
import { useVisible } from 'src/hooks/use-visible';

const AudienceSegmentationContainer = styled(FlexColumn)`
  margin-top: 4px;
  width: 406px;

  ${WizardStepSectionTitle} {
    margin-bottom: 2px;
  }

  ${WizardStepSectionDescription} {
    margin-bottom: 15px;
  }
`;

const FiltersButtonLightText = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 18px;
  text-align: left;
`;

const FiltersButton = styled(ButtonV2)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cosmo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  height: 34px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: left;
  white-space: break-spaces;
  width: max-content;

  &,
  &:hover {
    color: ${({ theme }) => theme.colors.smalt};
  }

  .lgg-icon {
    svg {
      height: 18px;
      width: 18px;

      path {
        fill: ${({ theme }) => theme.colors.smalt};
      }
    }
  }
`;

const AudienceSummaryContainer = styled(FlexColumn)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  margin-top: 20px;
  padding: 11px 15px;
`;

const AudienceSummaryItem = styled(FlexRow)`
  font-family: ${({ theme }) => theme.font.regular};
  justify-content: space-between;

  .result-divider {
    border-bottom: 1px solid ${({ theme }) => theme.colors.casper};
    padding-left: 10px;
  }
`;

const AudienceSummaryText = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 18px;
  text-align: right;
`;

const AudienceSummaryBoldText = styled(AudienceSummaryText)`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.bold};
`;

const AudienceSummaryLoadingContainer = styled(FlexRow)`
  padding: 21px 15px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  border-radius: 6px;
  justify-content: space-between;
  margin-top: 20px;
`;

const SummaryLoadingLeftRail = styled(FlexColumn)`
  width: 256px;
`;

const SummaryLoadingRightRail = styled(FlexColumn)`
  width: 40px;
`;

const StyledSkeleton = styled(Skeleton)`
  .ant-skeleton-content .ant-skeleton-paragraph {
    margin: 0;

    & > li {
      height: 12px;

      & + li {
        margin-top: 15px;
      }
    }
  }
`;

const getNegativeNumberOrZero = (value: number) =>
  value === 0 ? '0' : `- ${value.toLocaleString()}`;

const AudienceSummaryLoading = () => {
  const skeleton = (
    <StyledSkeleton title={false} paragraph={{ width: '100%', rows: 3 }} round active />
  );

  return (
    <AudienceSummaryLoadingContainer>
      <SummaryLoadingLeftRail>{skeleton}</SummaryLoadingLeftRail>
      <SummaryLoadingRightRail>{skeleton}</SummaryLoadingRightRail>
    </AudienceSummaryLoadingContainer>
  );
};

export const AudienceStep = ({ form }: BroadcastWizardStepProps) => {
  const { t } = useTranslation(['broadcast']);
  const filtersVisibilityHandler = useVisible();
  const convertFormValuesToWhereInput = useConvertFilterParamsToWhereInput();
  const [filterWhere, setFilterWhere] = useState<ContactWhereInput | undefined>(
    form.getValues().criteria
      ? convertFormValuesToWhereInput(form.getValues().criteria ?? {}, true)
      : undefined,
  );
  const [filterCount, setFilterCount] = useState<number>(
    getFiltersCount(form.getValues().criteria as Partial<ContactFiltersFormValues>),
  );

  useEffect(() => {
    const innerFormSubscription = form.watch(({ criteria }) => {
      if (criteria) {
        setFilterWhere(
          convertFormValuesToWhereInput(form.getValues().criteria ?? {}, true),
        );
        setFilterCount(getFiltersCount(criteria));
      }
    });

    return () => {
      innerFormSubscription.unsubscribe();
    };
  }, [convertFormValuesToWhereInput, form]);

  const { loading: loadingAudienceSummary, summary: audienceSummary } =
    useBroadcastAvailableContactsForChannelSms(
      Number(form.getValues().sender.id),
      cloneDeep(filterWhere),
    );

  useEffect(() => {
    form.setValue('uniqueContacts', audienceSummary?.contactsAvailable ?? null);
  }, [audienceSummary?.contactsAvailable, form]);

  return (
    <FlexColumn data-lgg-id="broadcast-wizard-step-audience">
      <AudienceSegmentationContainer>
        <WizardStepSectionTitle>
          {t('broadcast:pages.broadcastWizard.steps.audience.audienceSegmentation')}
        </WizardStepSectionTitle>
        <WizardStepSectionDescription>
          {t('broadcast:pages.broadcastWizard.steps.audience.audienceSegmentationInfo')}
        </WizardStepSectionDescription>
        <FiltersButton
          onClick={filtersVisibilityHandler.show}
          data-lgg-id="broadcast-wizard-contact-filters-button"
          type="button"
          variant="defaultWhite"
          icon="filters"
          size="regular"
          iconNotificationProps={{
            showNotification: filterCount > 0,
            notificationColor: 'cosmo',
          }}
        >
          <Trans
            i18nKey="broadcast:pages.broadcastWizard.steps.audience.contactFiltersAndCount"
            components={{
              light: <FiltersButtonLightText />,
            }}
            count={filterCount}
          />
        </FiltersButton>
        <WizardStepSectionDivider />
        {loadingAudienceSummary ? (
          <AudienceSummaryLoading />
        ) : audienceSummary ? (
          <AudienceSummaryContainer data-lgg-id="broadcast-wizard-audience-summary">
            <AudienceSummaryItem>
              <AudienceSummaryText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.audience.summary.contactsUnderCriteria',
                )}
              </AudienceSummaryText>
              <AudienceSummaryText data-lgg-id="broadcast-wizard-summary-contacts-matching">
                {audienceSummary.contactsMatchingCriteria.toLocaleString()}
              </AudienceSummaryText>
            </AudienceSummaryItem>
            <AudienceSummaryItem>
              <AudienceSummaryText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.audience.summary.contactsWithoutPhone',
                )}
              </AudienceSummaryText>
              <AudienceSummaryText data-lgg-id="broadcast-wizard-summary-contacts-without-phone">
                {getNegativeNumberOrZero(audienceSummary.contactsWithoutPhoneNumber)}
              </AudienceSummaryText>
            </AudienceSummaryItem>
            <AudienceSummaryItem>
              <AudienceSummaryText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.audience.summary.contactsWhoOptedOut',
                )}
              </AudienceSummaryText>
              <AudienceSummaryText
                className="result-divider"
                data-lgg-id="broadcast-wizard-summary-contacts-with-opt-out"
              >
                {getNegativeNumberOrZero(audienceSummary.contactsWithOptOut)}
              </AudienceSummaryText>
            </AudienceSummaryItem>
            <AudienceSummaryItem>
              <AudienceSummaryBoldText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.audience.summary.totalContacts',
                )}
              </AudienceSummaryBoldText>
              <AudienceSummaryBoldText data-lgg-id="broadcast-wizard-summary-total-contacts">
                {audienceSummary.contactsAvailable.toLocaleString()}
              </AudienceSummaryBoldText>
            </AudienceSummaryItem>
          </AudienceSummaryContainer>
        ) : null}
      </AudienceSegmentationContainer>
      <ContactFilters
        visible={filtersVisibilityHandler.visible}
        onClose={filtersVisibilityHandler.close}
        filters={form.getValues().criteria as Partial<ContactFiltersFormValues>}
        submitHandlerOverride={(values) => {
          if (values) {
            setFilterCount(getFiltersCount(values));

            const whereInput = convertFormValuesToWhereInput(values, true);

            setFilterWhere(whereInput);
            form.setValue('criteria', values ?? null);
          }
          filtersVisibilityHandler.close();
        }}
        onReset={() => {
          setFilterCount(0);
          setFilterWhere(undefined);
          form.setValue('criteria', null);
        }}
      />
    </FlexColumn>
  );
};
