import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { Progress } from 'antd';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import * as zod from 'zod';
import { getDatesDifferenceInUnits } from '@lgg/isomorphic/i18n/date-utils';
import {
  Broadcast,
  BroadcastDeliveryTimeCustomDay,
  Mutation,
  MutationCreateBroadcastArgs,
  MutationUpdateBroadcastArgs,
  MutationUpdateBroadcastStatusArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { getFiltersCount } from 'src/components/filters/react-filters';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import {
  ConfirmationModalContent,
  useShowConfirmationModal,
} from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { Checkbox } from 'src/components/general/inputs/checkbox';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastMessageContext } from 'src/components/pages/broadcast/components/broadcast-message-manager';
import { AudienceStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/audience-step';
import { GeneralStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/general-step';
import { MessageComposeStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/message-compose-step';
import { OverviewStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/overview-step';
import {
  NONE_DELIVERY_TIME_VALUE,
  ScheduleDetailsStep,
} from 'src/components/pages/broadcast/components/broadcast-wizard/steps/schedule-details-step';
import {
  getBroadcastMessageLanguage,
  getBroadcastSmsCounterValue,
} from 'src/components/pages/broadcast/components/broadcast-wizard/utils';
import { useHandleBroadcastError } from 'src/components/pages/broadcast/components/shared';
import {
  CREATE_BROADCAST_MUTATION,
  UPDATE_BROADCAST_MUTATION,
  UPDATE_BROADCAST_STATUS_MUTATION,
} from 'src/components/pages/broadcast/queries';
import { contactsFilterSchema } from 'src/components/pages/contacts/components/contact-filters';
import {
  useConvertFilterParamsToWhereInput,
  useConvertWhereInputToFilterParams,
} from 'src/components/pages/contacts/contact-queries';
import { useBlockNavigation } from 'src/hooks/use-block-navigation';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

export enum BroadcastWizardStep {
  GENERAL = 'GENERAL',
  AUDIENCE = 'AUDIENCE',
  MESSAGE_COMPOSE = 'MESSAGE_COMPOSE',
  SCHEDULE = 'SCHEDULE',
  OVERVIEW = 'OVERVIEW',
}

const broadcastWizardStepOrder: BroadcastWizardStep[] = [
  BroadcastWizardStep.GENERAL,
  BroadcastWizardStep.AUDIENCE,
  BroadcastWizardStep.MESSAGE_COMPOSE,
  BroadcastWizardStep.SCHEDULE,
  BroadcastWizardStep.OVERVIEW,
];

const StyledProgress = styled(Progress)`
  line-height: 0;

  .ant-progress-bg {
    background: ${({ theme }) => theme.colors.monk};
  }

  .ant-progress-inner {
    background: ${({ theme }) => theme.colors.koala};
  }
`;

const StepHeaderTopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const StepTitleIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const CenteredRow = styled(FlexRow)`
  align-items: center;
`;

const StepProgressIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const StepProgressText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 18px;
  text-align: right;
`;

const StepTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin: 0;
  text-align: left;
`;

const HeaderMainContainer = styled(FlexColumn)`
  margin-bottom: 24px;
  padding: 20px 20px 0;
`;

type BroadcastWizardHeaderConfiguration = {
  icon: string;
  title: string;
  progress: number;
};

type BroadcastWizardHeaderProps = {
  step: BroadcastWizardStep;
};

const BroadcastWizardHeader = ({ step }: BroadcastWizardHeaderProps) => {
  const { t } = useTranslation(['broadcast']);

  const configuration: BroadcastWizardHeaderConfiguration = useMemo(() => {
    // All cases handled for fixed values
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(step)
      .with(BroadcastWizardStep.GENERAL, () => {
        return {
          icon: 'broadcast',
          title: t('broadcast:pages.broadcastWizard.steps.general.title'),
          progress: 0,
        };
      })
      .with(BroadcastWizardStep.AUDIENCE, () => {
        return {
          icon: 'contactSetting',
          title: t('broadcast:pages.broadcastWizard.steps.audience.title'),
          progress: 25,
        };
      })
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => {
        return {
          icon: 'sms',
          title: t('broadcast:pages.broadcastWizard.steps.smsCompose.title'),
          progress: 50,
        };
      })
      .with(BroadcastWizardStep.SCHEDULE, () => {
        return {
          icon: 'calendarNoPadding',
          title: t('broadcast:pages.broadcastWizard.steps.scheduleDetails.title'),
          progress: 75,
        };
      })
      .with(BroadcastWizardStep.OVERVIEW, () => {
        return {
          icon: 'broadcastSummary',
          title: t('broadcast:pages.broadcastWizard.steps.overview.title'),
          progress: 100,
        };
      })
      .exhaustive();
  }, [step, t]);

  const { icon, title, progress } = configuration;

  return (
    <HeaderMainContainer data-lgg-id="broadcast-wizard-header">
      <StepHeaderTopContainer>
        <CenteredRow>
          <StepTitleIcon type={icon} />
          <StepTitle>{title}</StepTitle>
        </CenteredRow>
        <CenteredRow>
          <StepProgressIcon type="circularCheck" />
          <StepProgressText data-lgg-id="broadcast-wizard-progress">
            {t('broadcast:pages.broadcastWizard.progress', { progress })}
          </StepProgressText>
        </CenteredRow>
      </StepHeaderTopContainer>
      <StyledProgress percent={progress} showInfo={false} strokeWidth={4} />
    </HeaderMainContainer>
  );
};

const BaseContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
`;

const WizardForm = styled.form`
  display: flex;
  flex-direction: column;
  height: calc(100% - 87px);
`;

const MainContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-right: 30px;
  width: 100%;
`;

const StepContent = styled(FlexColumn)`
  height: 100%;
  padding: 0 20px;
`;

const StepFooter = styled(FlexRow)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  height: 78px;
  justify-content: space-between;
  margin-top: 10px;
  padding: 20px 30px;
`;

const SaveDraftIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

const StyledTertiaryButton = styled(LggButton)`
  background-color: ${({ theme }) => theme.colors.porcelain};
`;

const SaveDraftButton = styled(StyledTertiaryButton)`
  padding: 0 15px;
  width: max-content;
`;

const NextStepButton = styled(LggButton)`
  min-width: 62px;
`;

const BackButton = styled(StyledTertiaryButton)`
  margin-right: 15px;
  padding: 0 16px;
  width: 64px;
`;

const CancelButton = styled(StyledTertiaryButton)`
  margin-right: 15px;
  width: 79px;
`;

const SendToAllContactsMessageContainer = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18px;
  margin: 0;
  padding: 0 0 10px;
  white-space: break-spaces;

  strong {
    font-family: ${({ theme }) => theme.font.medium};
  }
`;

const ConsentSubTitle = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18px;
  margin-bottom: 20px;
`;

const ConsentCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 16px;
  margin: 0;
  top: 0;

  &.ant-checkbox-wrapper {
    align-items: flex-start;

    .ant-checkbox {
      top: 0;
    }

    & + & {
      margin-left: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 5px;
    }
  }

  strong {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-weight: normal;
  }

  & + & {
    margin-top: 15px;
  }
`;

const wizardFormTimeWindowSchema = zod.object({
  start: zod.string(),
  end: zod.string(),
});

const wizardFormCustomTimeWindowSchema = zod.object({
  start: zod.string(),
  end: zod.string().nullable(),
});

const CUSTOM_DELIVERY_TIME_PRESET_VALUE = 'CUSTOM';

const deliveryTimeCustomSchema = zod
  .object({
    '0': wizardFormCustomTimeWindowSchema.nullable(),
    '1': wizardFormCustomTimeWindowSchema.nullable(),
    '2': wizardFormCustomTimeWindowSchema.nullable(),
    '3': wizardFormCustomTimeWindowSchema.nullable(),
    '4': wizardFormCustomTimeWindowSchema.nullable(),
    '5': wizardFormCustomTimeWindowSchema.nullable(),
    '6': wizardFormCustomTimeWindowSchema.nullable(),
  })
  .nullish();

const deliveryTimePresetSchema = zod
  .object({
    presetType: zod
      .union([
        zod.literal('EVERYDAY'),
        zod.literal('WEEKDAYS'),
        zod.literal('WEEKEND'),
        zod.literal(CUSTOM_DELIVERY_TIME_PRESET_VALUE),
      ])
      .nullish(),
    timeWindow: wizardFormTimeWindowSchema.nullable(),
  })
  .nullish();

const wizardFormSchema = zod
  .object({
    id: zod.string().nullish(),
    name: zod.string().nullish(),
    sender: zod.object({
      id: zod.string().nullish(),
      label: zod.string().nullish(),
    }),
    message: zod.string().nullish(),
    companyName: zod.string().nullish(),
    optOutMessageLanguage: zod.union([zod.literal('EN'), zod.literal('ES')]).nullish(),
    scheduledStartAt: zod
      .object({
        date: zod.string().nullish(),
        timeInMinutes: zod.number().nullish(),
      })
      .nullish(),
    deliveryTimePreset: deliveryTimePresetSchema,
    deliveryTimeCustom: deliveryTimeCustomSchema,
    uniqueContacts: zod.number().nullish(),
    criteria: contactsFilterSchema.nullish(),
  })
  .required();

export type BroadcastWizardFormValues = zod.infer<typeof wizardFormSchema>;
export type BroadcastWizardCustom = zod.infer<typeof deliveryTimeCustomSchema>;
export type BroadcastWizardPreset = zod.infer<typeof deliveryTimePresetSchema>;

export type BroadcastWizardStepProps = {
  form: UseFormReturn<BroadcastWizardFormValues>;
};

export type BroadcastWizardProps = {
  step: BroadcastWizardStep;
  setWizardStep: ValueChanged<BroadcastWizardStep>;
  broadcast?: Broadcast;
};

export const splitTimeUnits = (time: string | null) => {
  const [hours = 0, minutes = 0, seconds = 0] = (time ?? '')
    .split(':')
    .map((value) => Number(value));

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getTimeMinutesEquivalent = (time: string) => {
  const { hours, minutes } = splitTimeUnits(time);

  return hours * 60 + minutes;
};

const useBroadcastToFormValues = () => {
  const { parseISO, startOfDay } = useDateHelpers();
  const { convertWhereInputToFilterParams } = useConvertWhereInputToFilterParams();

  const broadcastToFormValues = useCallback(
    (broadcast: Broadcast): BroadcastWizardFormValues => {
      const { name, criteria } = broadcast;
      const broadcastChannelConfiguration = match(broadcast?.channelsConfiguration?.[0])
        .with(
          { __typename: 'BroadcastChannelSmsConfiguration' },
          ({ content, resource, optOutMessage, companyName }) => {
            const message = (content ?? [])
              .filter((value) => value.type === 'MESSAGE')
              .map((value) => value.message)
              .join('');

            const sender = resource;

            return {
              message,
              optOutMessage,
              sender,
              companyName,
            };
          },
        )
        .otherwise(() => {
          return {
            message: '',
            sender: null,
            optOutMessage: '',
            companyName: '',
          };
        });

      const deliveryTimePreset = match(broadcast.deliveryTime)
        .with({ __typename: 'BroadcastDeliveryTimePreset' }, (preset) => {
          return {
            deliveryTimePreset: {
              presetType: preset.presetType,
              timeWindow: {
                start: preset.timeWindow.startTime,
                end: preset.timeWindow.endTime,
              },
            },
            deliveryTimeCustom: null,
          };
        })
        .with({ __typename: 'BroadcastDeliveryTimeCustom' }, (customPreset) => {
          const defaultValue = {
            start: NONE_DELIVERY_TIME_VALUE,
            end: null,
          };
          const customDeliveryValues: BroadcastWizardCustom = {
            '0': defaultValue,
            '1': defaultValue,
            '2': defaultValue,
            '3': defaultValue,
            '4': defaultValue,
            '5': defaultValue,
            '6': defaultValue,
          };

          customPreset.daysOfWeek.forEach(({ dayOfWeek, timeWindow }) => {
            const isValidKey = dayOfWeek >= 0 && dayOfWeek <= 6;

            if (isValidKey) {
              customDeliveryValues[dayOfWeek] = {
                start: timeWindow.startTime as string,
                end: timeWindow.endTime as string,
              };
            }
          });

          const preset: BroadcastWizardPreset = {
            presetType: 'CUSTOM',
            timeWindow: null,
          };

          return {
            deliveryTimePreset: preset,
            deliveryTimeCustom: customDeliveryValues,
          };
        })
        .otherwise(() => ({ deliveryTimePreset: null, deliveryTimeCustom: null }));

      return {
        id: broadcast.id,
        name: name ?? '',
        sender: {
          id: broadcastChannelConfiguration.sender?.id ?? null,
          label: broadcastChannelConfiguration.sender?.phoneNumber?.national ?? null,
        },
        message: broadcastChannelConfiguration.message,
        companyName: broadcastChannelConfiguration.companyName ?? null,
        optOutMessageLanguage: getBroadcastMessageLanguage(
          broadcastChannelConfiguration.optOutMessage,
        ),
        scheduledStartAt: broadcast.scheduledAt
          ? {
              date: broadcast.scheduledAt,
              timeInMinutes: getDatesDifferenceInUnits(
                startOfDay(parseISO(broadcast.scheduledAt)),
                parseISO(broadcast.scheduledAt),
              ).minutes,
            }
          : null,
        uniqueContacts: broadcast.audience ?? null,
        criteria: convertWhereInputToFilterParams(criteria) ?? null,
        ...deliveryTimePreset,
      };
    },
    [convertWhereInputToFilterParams, parseISO, startOfDay],
  );

  return {
    broadcastToFormValues,
  };
};

export const BroadcastWizard = ({
  step,
  setWizardStep,
  broadcast,
}: BroadcastWizardProps) => {
  const { t } = useTranslation(['common', 'broadcast']);
  const { set, parseISO, startOfDay } = useDateHelpers();
  const showNotification = useShowNotification();
  const { id: institutionId } = useCurrentInstitution();
  const { getBroadcastPageUrl } = useUrls();
  const history = useHistory();
  const { showBroadcastMessage } = useContext(BroadcastMessageContext);
  const { broadcastToFormValues } = useBroadcastToFormValues();
  const convertFormValuesToWhereInput = useConvertFilterParamsToWhereInput();
  const handleBroadcastError = useHandleBroadcastError();
  const [savingDraft, setSavingDraft] = useState<boolean>(false);
  const showConfirmationModal = useShowConfirmationModal();
  const { setBlockNavigation } = useBlockNavigation({
    shouldBlock: true,
    onBlock: (setBlockNavigation, pathname) => {
      showConfirmationModal({
        title: t('broadcast:pages.broadcastWizard.cancelConfirmationModal.title'),
        message: t('broadcast:pages.broadcastWizard.cancelConfirmationModal.message'),
        cancelButtonText: t(
          'broadcast:pages.broadcastWizard.cancelConfirmationModal.cancelButtonText',
        ),
        confirmButtonText: t(
          'broadcast:pages.broadcastWizard.cancelConfirmationModal.confirmButtonText',
        ),
        confirmButtonType: 'delete',
        testId: 'cancel-broadcast-confirm-message',
        onConfirm: async () => {
          setBlockNavigation(false);
          history.push(pathname);
        },
        onCancel: async () => {
          await wizardForm.handleSubmit(
            async () => {
              await handleSaveDraft();
            },
            async () => {
              showInvalidFromValuesError();
            },
          )();
        },
      });
    },
  });
  const broadcastConsentModalVisibilityHandler = useVisible();
  const [broadcastConsent, setBroadcastConsent] = useState<{
    messageConsent: boolean;
    contactsConsent: boolean;
  }>({ contactsConsent: false, messageConsent: false });

  const [createBroadcast, { loading: creatingBroadcast }] = useMutation<
    Pick<Mutation, 'createBroadcast'>,
    MutationCreateBroadcastArgs
  >(CREATE_BROADCAST_MUTATION);

  const [updateBroadcast, { loading: updatingBroadcast }] = useMutation<
    Pick<Mutation, 'updateBroadcast'>,
    MutationUpdateBroadcastArgs
  >(UPDATE_BROADCAST_MUTATION);

  const [updateBroadcastStatus, { loading: updatingBroadcastStatus }] = useMutation<
    Pick<Mutation, 'updateBroadcastStatus'>,
    MutationUpdateBroadcastStatusArgs
  >(UPDATE_BROADCAST_STATUS_MUTATION);

  const formInitialValues = useMemo(() => {
    return broadcast
      ? broadcastToFormValues(broadcast)
      : {
          id: null,
          name: null,
          sender: { id: null, label: null },
          message: null,
          companyName: null,
          optOutMessage: null,
          optOutMessageLanguage: 'EN' as const,
          scheduledStartAt: null,
          deliveryTimePreset: null,
          deliveryTimeCustom: null,
          uniqueContacts: null,
          criteria: null,
        };
  }, [broadcast, broadcastToFormValues]);

  const wizardForm = useForm<BroadcastWizardFormValues>({
    defaultValues: formInitialValues,
    values: formInitialValues,
    shouldFocusError: true,
  });

  const wizardStepContent = useMemo(() => {
    // All cases handled; no fallback
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(step)
      .with(BroadcastWizardStep.GENERAL, () => <GeneralStep form={wizardForm} />)
      .with(BroadcastWizardStep.AUDIENCE, () => <AudienceStep form={wizardForm} />)
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => (
        <MessageComposeStep form={wizardForm} />
      ))
      .with(BroadcastWizardStep.SCHEDULE, () => <ScheduleDetailsStep form={wizardForm} />)
      .with(BroadcastWizardStep.OVERVIEW, () => <OverviewStep form={wizardForm} />)
      .exhaustive();
  }, [step, wizardForm]);

  const showBackButton = useMemo(() => step !== BroadcastWizardStep.GENERAL, [step]);

  const showSendButton = useMemo(() => step === BroadcastWizardStep.OVERVIEW, [step]);

  const getCreateOrUpdateBroadcastPayload = useCallback(() => {
    const {
      name,
      scheduledStartAt,
      optOutMessageLanguage,
      companyName,
      sender,
      deliveryTimePreset,
      deliveryTimeCustom,
      criteria,
      message,
    } = wizardForm.getValues();
    const presetType = deliveryTimePreset?.presetType;
    const scheduledStartAtDate = scheduledStartAt?.date
      ? set(startOfDay(parseISO(scheduledStartAt?.date)), {
          minutes: Number(scheduledStartAt.timeInMinutes ?? 0),
        })
      : null;

    const deliveryTime = match({
      presetType,
      deliveryTimeCustom,
      deliveryTimePreset,
    })
      .with(
        {
          presetType: CUSTOM_DELIVERY_TIME_PRESET_VALUE,
          deliveryTimeCustom: P.nonNullable,
        },
        ({ deliveryTimeCustom }) => {
          const entries = Object.entries(deliveryTimeCustom ?? {}).map(([key, value]) => {
            return value && value.start !== NONE_DELIVERY_TIME_VALUE
              ? {
                  dayOfWeek: Number(key),
                  timeWindow: {
                    endTime: value.end,
                    startTime: value.start,
                  },
                }
              : null;
          });

          return {
            deliveryTimeCustom: {
              daysOfWeek: entries.filter(
                (value) => value !== null,
              ) as BroadcastDeliveryTimeCustomDay[],
            },
          };
        },
      )
      .with(
        {
          presetType: P.union('WEEKDAYS', 'WEEKEND', 'EVERYDAY'),
          deliveryTimePreset: {
            timeWindow: P.nonNullable,
          },
        },
        ({ deliveryTimePreset, presetType }) => {
          return {
            deliveryTimePreset: {
              presetType: presetType,
              timeWindow: {
                startTime: deliveryTimePreset.timeWindow.start,
                endTime: deliveryTimePreset.timeWindow.end,
              },
            },
          };
        },
      )
      .otherwise(() => ({}));

    return {
      input: {
        companyId: institutionId,
        name,
        scheduledAt: scheduledStartAtDate,
        criteria: criteria
          ? cloneDeep(convertFormValuesToWhereInput(criteria, true))
          : null,
        channelsConfigurationSms: sender?.id
          ? {
              optOutMessage: optOutMessageLanguage,
              resourceId: sender.id.toString(),
              companyName,
              messageContent: message,
            }
          : null,
        ...deliveryTime,
      },
    };
  }, [
    convertFormValuesToWhereInput,
    institutionId,
    parseISO,
    set,
    startOfDay,
    wizardForm,
  ]);

  const handleBack = useCallback(() => {
    const currentStepIndex = broadcastWizardStepOrder.indexOf(step);

    if (currentStepIndex > 0) {
      setWizardStep(broadcastWizardStepOrder[currentStepIndex - 1]);
    }
  }, [setWizardStep, step]);

  const goToIndexPage = useCallback(() => {
    history.push(getBroadcastPageUrl());
  }, [getBroadcastPageUrl, history]);

  const confirmMessageContent = useMemo(() => {
    const {
      scheduledStartAt,
      id: broadcastId,
      uniqueContacts,
      message,
      optOutMessageLanguage,
      companyName,
    } = wizardForm.getValues();
    const baseInput = getCreateOrUpdateBroadcastPayload();
    const { contactsConsent, messageConsent } = broadcastConsent;

    const onCompletedHandler = async (broadcast: Broadcast) => {
      if (broadcast?.id) {
        await updateBroadcastStatus({
          variables: {
            input: {
              id: broadcast.id,
              status: 'SCHEDULED',
            },
          },
          onCompleted: () => {
            setBlockNavigation(false);
            showBroadcastMessage(!scheduledStartAt ? 'CREATED' : 'SCHEDULED');
            goToIndexPage();
          },
          onError: handleBroadcastError,
        });
      }
    };

    const messagesCount =
      getBroadcastSmsCounterValue({
        message,
        language: optOutMessageLanguage,
        companyName,
      }) * (uniqueContacts ?? 0);

    return (
      <ConfirmationModalContent
        onClose={broadcastConsentModalVisibilityHandler.close}
        buttonSize="regular"
        maxWidth={450}
        {...{
          title: t('broadcast:pages.broadcastWizard.broadcastConsentModal.title'),
          message: (
            <FlexColumn>
              <ConsentSubTitle>
                {t('broadcast:pages.broadcastWizard.broadcastConsentModal.subTitle')}
              </ConsentSubTitle>
              <ConsentCheckbox
                checked={messageConsent}
                data-lgg-id="message-count-consent-checkbox"
                onChange={(e) => {
                  setBroadcastConsent((value) => ({
                    ...value,
                    messageConsent: !messageConsent,
                  }));
                }}
              >
                <Trans
                  i18nKey="broadcast:pages.broadcastWizard.broadcastConsentModal.messagesCountConsent"
                  components={{ strong: <strong /> }}
                  count={messagesCount}
                  values={{
                    messagesCount: messagesCount.toLocaleString(),
                  }}
                />
              </ConsentCheckbox>
              <ConsentCheckbox
                checked={contactsConsent}
                data-lgg-id="contacts-count-consent-checkbox"
                onChange={(e) => {
                  setBroadcastConsent((value) => ({
                    ...value,
                    contactsConsent: !contactsConsent,
                  }));
                }}
              >
                <Trans
                  i18nKey="broadcast:pages.broadcastWizard.broadcastConsentModal.contactsCountConsent"
                  components={{ strong: <strong /> }}
                  count={uniqueContacts ?? 0}
                  values={{
                    contactsCount: uniqueContacts?.toLocaleString(),
                  }}
                />
              </ConsentCheckbox>
            </FlexColumn>
          ),
          confirmButtonText: t(
            'broadcast:pages.broadcastWizard.broadcastConsentModal.confirmButtonText',
          ),
          confirmButtonType: 'cta',
          confirmButtonDisabled: !contactsConsent || !messageConsent,
          testId: 'broadcast-consent-confirm-message',
          onConfirm: async () => {
            if (broadcastId) {
              await updateBroadcast({
                variables: {
                  input: {
                    ...baseInput.input,
                    id: broadcastId,
                  },
                },
                onCompleted: async ({ updateBroadcast }) => {
                  await onCompletedHandler(updateBroadcast);
                },
                onError: handleBroadcastError,
              });
            } else {
              await createBroadcast({
                variables: {
                  ...baseInput,
                },
                onCompleted: async ({ createBroadcast }) => {
                  if (createBroadcast) {
                    await onCompletedHandler(createBroadcast);
                  }
                },
                onError: handleBroadcastError,
              });
            }
          },
        }}
      />
    );
  }, [
    broadcastConsent,
    broadcastConsentModalVisibilityHandler.close,
    createBroadcast,
    getCreateOrUpdateBroadcastPayload,
    goToIndexPage,
    handleBroadcastError,
    setBlockNavigation,
    showBroadcastMessage,
    t,
    updateBroadcast,
    updateBroadcastStatus,
    wizardForm,
  ]);

  const handleNext = useCallback(async () => {
    const currentStepIndex = broadcastWizardStepOrder.indexOf(step);

    if (currentStepIndex < broadcastWizardStepOrder.length - 1) {
      const isAudienceStep = step === BroadcastWizardStep.AUDIENCE;
      const moveToNextStep = () => {
        setWizardStep(broadcastWizardStepOrder[currentStepIndex + 1]);
      };

      if (isAudienceStep && !wizardForm.getValues().uniqueContacts) {
        showNotification({
          type: 'error',
          title: t(
            'broadcast:pages.broadcastWizard.errorMessages.noContactsToSend.title',
          ),
          message: t(
            'broadcast:pages.broadcastWizard.errorMessages.noContactsToSend.message',
          ),
        });
      } else if (
        isAudienceStep &&
        getFiltersCount(wizardForm.getValues().criteria ?? {}) === 0
      ) {
        showConfirmationModal({
          title: t(
            'broadcast:pages.broadcastWizard.steps.audience.confirmationModals.sendToAllContacts.title',
          ),
          message: (
            <SendToAllContactsMessageContainer>
              <Trans
                i18nKey="broadcast:pages.broadcastWizard.steps.audience.confirmationModals.sendToAllContacts.content"
                components={{
                  strong: <span className="strong" />,
                }}
              />
            </SendToAllContactsMessageContainer>
          ),
          confirmButtonText: t(
            'broadcast:pages.broadcastWizard.steps.audience.confirmationModals.sendToAllContacts.confirmButtonText',
          ),
          confirmButtonType: 'primary',
          testId: 'send-to-all-contacts-confirm-message',
          onConfirm: moveToNextStep,
        });
      } else {
        moveToNextStep();
      }
    } else {
      broadcastConsentModalVisibilityHandler.show();
    }
  }, [
    step,
    wizardForm,
    setWizardStep,
    showNotification,
    t,
    showConfirmationModal,
    broadcastConsentModalVisibilityHandler,
  ]);

  const handleSaveDraft = useCallback(async () => {
    setSavingDraft(true);
    const targetBroadcastId = wizardForm.getValues().id;

    const onCompleteHandler = () => {
      setBlockNavigation(false);
      goToIndexPage();
    };

    const onErrorHandler = (error: ApolloError) => {
      setSavingDraft(false);
      handleBroadcastError(error);
    };

    if (targetBroadcastId) {
      await updateBroadcast({
        variables: {
          input: {
            id: targetBroadcastId,
            ...getCreateOrUpdateBroadcastPayload().input,
          },
        },
        onCompleted: onCompleteHandler,
        onError: onErrorHandler,
      });
    } else {
      await createBroadcast({
        variables: getCreateOrUpdateBroadcastPayload(),
        onCompleted: onCompleteHandler,
        onError: onErrorHandler,
      });
    }
  }, [
    createBroadcast,
    getCreateOrUpdateBroadcastPayload,
    goToIndexPage,
    handleBroadcastError,
    setBlockNavigation,
    updateBroadcast,
    wizardForm,
  ]);

  const showInvalidFromValuesError = useCallback(() => {
    showNotification({
      type: 'error',
      title: t('broadcast:pages.broadcastWizard.errorMessages.invalidFormValues.title'),
      message: t(
        'broadcast:pages.broadcastWizard.errorMessages.invalidFormValues.message',
      ),
    });
  }, [showNotification, t]);

  return (
    <MainContainer>
      <BroadcastWizardHeader step={step} />

      <FormProvider {...wizardForm}>
        <WizardForm
          onSubmit={wizardForm.handleSubmit(
            async () => {
              await handleNext();
            },
            () => {
              showInvalidFromValuesError();
            },
          )}
        >
          <Scrollbar>
            <StepContent>{wizardStepContent}</StepContent>
          </Scrollbar>
          <StepFooter>
            <FlexRow>
              <CancelButton
                variant="tertiary"
                data-lgg-id="broadcast-wizard-cancel-button"
                type="button"
                onClick={() => {
                  goToIndexPage();
                }}
              >
                {t('common:cancel')}
              </CancelButton>
              <SaveDraftButton
                variant="tertiary"
                data-lgg-id="broadcast-wizard-save-draft-button"
                type="button"
                loading={savingDraft}
                onClick={wizardForm.handleSubmit(async () => {
                  await handleSaveDraft();
                })}
              >
                <SaveDraftIcon type="draft" />
                {t('common:saveDraft')}
              </SaveDraftButton>
            </FlexRow>
            <FlexRow>
              {showBackButton && (
                <BackButton
                  variant="tertiary"
                  data-lgg-id="broadcast-wizard-back-button"
                  type="button"
                  onClick={handleBack}
                >
                  {t('common:back')}
                </BackButton>
              )}
              {showSendButton ? (
                <LggButton
                  variant="secondary"
                  type="submit"
                  data-lgg-id="broadcast-wizard-send-button"
                  disabled={creatingBroadcast}
                  loading={
                    creatingBroadcast || updatingBroadcast || updatingBroadcastStatus
                  }
                >
                  {t(
                    wizardForm.getValues().scheduledStartAt !== null
                      ? 'broadcast:pages.broadcastWizard.scheduleBroadcast'
                      : 'broadcast:pages.broadcastWizard.sendBroadcast',
                  )}
                </LggButton>
              ) : (
                <NextStepButton
                  variant="primary"
                  type="submit"
                  data-lgg-id="broadcast-wizard-next-button"
                >
                  {t('common:next')}
                </NextStepButton>
              )}
            </FlexRow>
          </StepFooter>
        </WizardForm>
      </FormProvider>
      <LggModal
        visible={broadcastConsentModalVisibilityHandler.visible}
        onClose={broadcastConsentModalVisibilityHandler.close}
        maskClosable
        closeIcon={<></>}
        closable={false}
        style={{
          maxWidth: '450px',
        }}
        afterClose={() => {
          setBroadcastConsent({
            contactsConsent: false,
            messageConsent: false,
          });
        }}
      >
        {confirmMessageContent}
      </LggModal>
    </MainContainer>
  );
};
