import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { ContactModalZIndex } from 'src/components/constants';
import {
  ContactModalOpenLegacyCallModalIcon,
  ContactModalTabs,
  ContactModalViewProps,
  ContactOptionsDisplayProps,
  MoreOptionsIcon,
  useContactOptions,
} from 'src/components/domain/contacts/contact-modal/contact-modal.shared';
import { ContactModalLoadingView } from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-tabs.shared';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  BlockContactModal,
  useContactBlock,
} from 'src/components/pages/contacts/components/contact-block';
import { ContactAddNewDropdownWithTitle } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-list-header';
import { useFormatLastInteractionDate } from 'src/components/pages/conversations/hooks/use-format-last-interaction-date';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';
import { ContactDetails } from '../contact-details/contact-details';

const ContactOptionsDropdown = memo<
  ContactOptionsDisplayProps & { onBlockContactOptionClicked: VoidFunction }
>(({ visibilityHandler, contact, onBlockContactOptionClicked }) => {
  const contactOptions = useContactOptions({
    contact,
    onBlockContactOptionClicked,
  });

  if (!contactOptions) {
    return null;
  }

  return (
    <LggOptionsDropdownButtonWithCustomTrigger
      visibilityHandler={visibilityHandler}
      customTrigger={
        <MoreOptionsIcon
          data-lgg-id="contact-options-dropdown-trigger"
          type="moreOptionsWithPadding"
        />
      }
      options={contactOptions}
    />
  );
});

const StyledModal = styled(LggModal)`
  height: calc(100% - 60px);
  margin: 0 auto;
  max-width: 1400px;
  padding-bottom: 0;

  .ant-modal-content {
    height: 100%;
    max-width: unset;
    width: 100%;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    height: 100%;
  }
`;

const ModalContent = styled(FlexColumn)`
  height: 100%;
`;

const ModalTitle = styled.span`
  color: ${(props) => props.theme.colors.carbon};
  font-family: ${(props) => props.theme.font.regular};
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.48px;
  line-height: 30px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`;

const ModalSubTitle = styled.span`
  align-items: center;
  color: ${(props) => props.theme.colors.flint};
  display: flex;
  font-family: ${(props) => props.theme.font.regular};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 17px;
  margin-top: 1px;
  text-align: left;
`;

const ModalHeader = styled(FlexRow)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  height: 90px;
  min-height: 90px;
  padding: 0 20px;
  width: 100%;
`;

const LabelColumn = styled(FlexColumn)`
  flex: 1;
  min-width: 0;
`;

const ButtonsRow = styled(FlexRow)`
  align-items: center;

  > * {
    margin-left: 10px;
  }
`;

const ModalBody = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.porcelain};
  flex: 1;
  padding: 20px;
`;

const ContactDetailsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  margin-left: 19px;
  min-width: 206px;
  width: 206px;
  padding-top: 15px;

  ${up('lg')} {
    min-width: 304px;
    width: 304px;
  }
`;

const CloseIcon = styled(Icon)`
  align-self: center;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  ${up('md')} {
    && {
      margin-left: 20px;
    }
  }
`;

const EditContactButton = styled(ButtonV2)`
  margin-left: 15px;
`;

type DesktopViewProps = ContactModalViewProps;

export const ContactModalDesktopView = memo<DesktopViewProps>(
  ({ contact, onClose, loading }) => {
    const { formatLastInteractionDate } = useFormatLastInteractionDate();
    const { getLegacyContactEditUrl } = useUrls();
    const { getFeatureFlag } = useAuthorization();
    const { t } = useTranslation(['common', 'contacts']);
    const hasCrmFeatureFlag = getFeatureFlag('crm');
    const moreOptionsDrawerVisibility = useVisible();
    const { changeBlockStatus, blockedIndicator } = useContactBlock({ contact });
    const blockContactModalVisibility = useVisible();

    return (
      <StyledModal
        data-lgg-id="contact-modal"
        visible={true}
        zIndex={ContactModalZIndex}
        closeIcon={null}
        onClose={onClose}
        width="calc(100% - 60px)"
        maskTransitionName=""
      >
        <ModalContent>
          {match({ contact, loading })
            .with({ contact: P.nonNullable }, ({ contact }) => {
              const occurredAt = contact.lastContactInteraction?.occurredAt;

              return (
                <>
                  <ModalHeader>
                    <LabelColumn>
                      <ModalTitle>{contact.label}</ModalTitle>
                      <ModalSubTitle>
                        {blockedIndicator && (
                          <>
                            {blockedIndicator}
                            {' · '}
                          </>
                        )}
                        {occurredAt
                          ? formatLastInteractionDate(occurredAt)
                          : t('common:noInteractionLogged')}
                      </ModalSubTitle>
                    </LabelColumn>
                    <ButtonsRow>
                      {contact.primaryPhone && (
                        <ContactModalOpenLegacyCallModalIcon
                          contact={contact}
                          data-lgg-id="contact-interaction-list-header-phone-call"
                        />
                      )}
                      {hasCrmFeatureFlag && (
                        <ContactAddNewDropdownWithTitle contactId={contact.id} />
                      )}
                      <EditContactButton
                        icon="edit"
                        size="regular"
                        variant="default"
                        onClick={() =>
                          openRemoteModalWrapper(getLegacyContactEditUrl(contact.id))
                        }
                      >
                        {t('contacts:actions.editContact')}
                      </EditContactButton>
                      <ContactOptionsDropdown
                        visibilityHandler={moreOptionsDrawerVisibility}
                        contact={contact}
                        onBlockContactOptionClicked={() =>
                          changeBlockStatus(blockContactModalVisibility)
                        }
                      />
                      <BlockContactModal
                        visibilityHandler={blockContactModalVisibility}
                        contact={contact}
                      />
                      <CloseIcon
                        onClick={onClose}
                        type="close"
                        lggTestId="contact-modal-close-icon"
                      />
                    </ButtonsRow>
                  </ModalHeader>
                  <ModalBody>
                    <ContactModalTabs contact={contact} />
                    <ContactDetailsContainer>
                      <ContactDetails contact={contact} />
                    </ContactDetailsContainer>
                  </ModalBody>
                </>
              );
            })
            .with({ loading: true }, () => <ContactModalLoadingView />)
            .otherwise(() => null)}
        </ModalContent>
      </StyledModal>
    );
  },
);
