import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useApolloClient, useQuery } from '@apollo/client';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  ConversationItemsWhereInput,
  QueryResourceWhatsappArgs,
  WhatsappTemplate,
  Query,
} from '@lgg/isomorphic/types/__generated__/graphql';
import ChatBg from 'src/assets/images/conversations/bg-pattern.svg?url';
import {
  LggDropdownButtonWithoutOverlay,
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
} from 'src/components/general/button/dropdown-button';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Tooltip } from 'src/components/general/display/tooltip';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { SelectableOptionsDrawer } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { Icon } from 'src/components/general/icon';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { Center } from 'src/components/layout/center';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { LoadingIndicator } from 'src/components/loading-fallback';
import {
  useWhatsappTemplateQuery,
  useConversationSendWhatsappTemplateMutation,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/queries';
import { WhatsappTemplateListItem } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/whatsapp-template-list-item';
import { CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { WhatsappTemplatePreviewItem } from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/whatsapp-subitems/whatsapp-template-item';
import { DrawerHeaderBackIcon } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import {
  conversationDropdownButtonStyles,
  ConversationTextFilterInput,
} from 'src/components/pages/conversations/components/filters/shared';
import {
  ManageWhatsappTemplatesButton,
  WhatsappSyncButton,
} from 'src/components/pages/whatsapp-channel/components/shared';
import { getManageTemplatesUrl } from 'src/components/pages/whatsapp-channel/shared/helpers';
import {
  useHandleSyncWhatsappTemplates,
  useWhatsappResourceSetActive,
} from 'src/components/pages/whatsapp-channel/shared/hooks';
import { RESOURCE_WHATSAPP_QUERY } from 'src/components/pages/whatsapp-channel/shared/queries';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDebouncedState } from 'src/hooks/use-debounced-state';
import { useVisible } from 'src/hooks/use-visible';
import { publishEvent } from 'src/utils/events/pub-sub';

const AddWhatsappTemplateModal = styled(LggModal)`
  .ant-modal-content {
    max-width: 740px;

    .ant-modal-close {
      top: 23px;
      right: 20px;
    }
  }
`;

const ModalHeaderContainer = styled(FlexRow)`
  align-items: center;
  height: 64px;
  padding: 20px 63px 20px 20px;
  width: 740px;
`;

const ModalHeaderTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 24px;
  margin: 0;
  flex: 1;
`;

const FooterActionButton = styled(LggButton)`
  ${up('md')} {
    font-size: 12px;
  }
`;

const ModalFooterContainer = styled(FlexRow)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  height: 68px;
  justify-content: flex-end;
  padding: 20px 15px;

  button + button {
    margin-left: 20px;
  }

  ${up('md')} {
    height: 64px;

    button + button {
      margin-left: 15px;
    }

    ${FooterActionButton} {
      height: 34px;
      padding: 10px 15px;
      width: max-content;
    }
  }
`;

const HeaderTopContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.alabaster};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 64px;
  padding: 15px 20px;
`;

const HeaderBottomContainer = styled(FlexColumn)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
  height: 64px;
  justify-content: center;
  padding: 15px 20px;
`;

const TemplateCount = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  flex: 1;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  line-height: 18px;
  margin: 0;
`;

const TemplatePickerBottomDrawer = styled(BottomDrawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const LanguageDropdownButton = styled(LggDropdownButtonWithoutOverlay)`
  ${conversationDropdownButtonStyles};
  border: 1px solid rgba(152, 169, 188, 0.25);
  min-width: 99px;
  padding-right: 10px;

  span[role='img'] {
    margin-left: 5px;
  }

  ${up('md')} {
    padding-right: 15px;
    min-width: 160px;

    span[role='img'] {
      margin-left: 10px;
    }
  }
`;

const TemplateSearchInput = styled(ConversationTextFilterInput)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  font-size: 14px;
  line-height: 17px;
  background-color: white;

  .lgg-icon {
    svg path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  ${up('md')} {
    input {
      padding: 11px 10px;
    }
  }
`;

const ModalBodyContainer = styled.div`
  background: ${({ theme }) => theme.colors.porcelain};
  padding: 20px;
`;

const TemplatePreviewContainer = styled(ModalBodyContainer)`
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${ChatBg});
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.porcelain};
    height: 527px;
  }
`;

const TemplatePreviewTipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryTopaz10};
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.secondaryTopaz60};
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
  text-align: left;

  strong {
    font-family: ${({ theme }) => theme.font.medium};
  }
`;

const FilterLabel = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 1px;
  text-transform: capitalize;
`;

const SearchInputContainer = styled(FlexColumn)`
  margin-left: 15px;
`;

const ModalBodyTopContent = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TriggerIcon = styled(Icon)`
  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }

  &.active,
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;

const baseTemplateListStyles = css`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ${up('md')} {
    height: 420px;
    border-radius: 4px;
  }
`;

const LoadingContainer = styled(FlexRow)`
  ${baseTemplateListStyles};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WhatsappTemplateListContainer = styled.div`
  ${baseTemplateListStyles};
  overflow: hidden;
  position: relative;

  &::after {
    background-image: linear-gradient(
      to bottom,
      #fff0,
      ${({ theme }) => theme.colors.white} 88%
    );
    bottom: -1px;
    content: '';
    height: 30px;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
  }
`;

const TemplateListFooter = styled.div`
  height: 30px;
`;

type SendTemplateOptionIconProps = {
  showModal: () => void;
  visible: boolean;
};

export const SendTemplateOptionIcon = memo<SendTemplateOptionIconProps>(
  ({ showModal, visible }) => {
    const { t } = useTranslation(['conversations']);
    const tooltipVisibilityHandler = useVisible();
    const breakpointUpMd = useBreakpoint(up('md'));

    useEffect(() => {
      if (visible && tooltipVisibilityHandler.visible) {
        tooltipVisibilityHandler.close();
      }
    }, [tooltipVisibilityHandler, visible]);

    return (
      <Tooltip
        title={t(
          'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.templateOptionTooltip',
        )}
        visible={breakpointUpMd && tooltipVisibilityHandler.visible}
      >
        <span
          onMouseEnter={tooltipVisibilityHandler.show}
          onMouseLeave={tooltipVisibilityHandler.close}
        >
          <Center>
            <TriggerIcon
              type="whatsappTemplates"
              lggTestId="input-area-whatsapp-template-picker-trigger"
              onClick={showModal}
              className={c({ active: visible })}
            />
          </Center>
        </span>
      </Tooltip>
    );
  },
);

const TemplateScrollContainer = styled(Scrollbar)`
  height: 100%;
  padding-bottom: 20px;
  margin-bottom: 10px;
`;

const ActionItems = styled(FlexRow)`
  align-items: center;
  gap: 10px;

  & span {
    width: 1px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.flint};
  }

  & a {
    white-space: nowrap;
    font-size: 12px;

    ${up('md')} {
      font-size: 13px;
    }
  }
`;

type WhatsAppTemplateTextParameter = {
  type: 'text';
  text: string;
};

export type WhatsappTemplateParameterInput = {
  key: string;
  hasError?: boolean;
  value?: WhatsAppTemplateTextParameter;
};

export type WhatsappTemplateButtonParameterInput = {
  type: string;
  subType: string;
};

export type WhatsappTemplateData = {
  header: WhatsappTemplateParameterInput[];
  body: WhatsappTemplateParameterInput[];
  button: WhatsappTemplateButtonParameterInput | null;
  rawBody?: string;
  rawHeader?: string;
  rawFooter?: string;
  bodyParamsCount: number;
  headerParamsCount: number;
};

const templateParametersDefaultValue: WhatsappTemplateData = {
  body: [],
  header: [],
  button: null,
  rawBody: undefined,
  rawHeader: undefined,
  rawFooter: undefined,
  headerParamsCount: 0,
  bodyParamsCount: 0,
};

const StyledEmptyState = styled(FlexColumn)`
  ${baseTemplateListStyles};
  align-items: center;
  padding: 70px 30px 0;

  ${up('md')} {
    padding: 93px 170px 0;
  }

  .iconContainer {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.porcelain};
    border-radius: 50%;
    display: flex;
    height: 90px;
    justify-content: center;
    width: 90px;

    svg {
      height: 30px;
      width: 30px;

      path {
        fill: ${({ theme }) => theme.colors.gogo};
      }
    }
  }

  .title {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.36px;
    line-height: 22px;
    margin: 20px 0 10px;
    text-align: center;
  }

  .message {
    color: ${({ theme }) => theme.colors.flint};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 18px;
    text-align: center;

    a,
    a:visited,
    a:active,
    a:hover {
      color: ${({ theme }) => theme.colors.gogo};
      font-family: ${({ theme }) => theme.font.medium};
    }
  }
`;

const EmptyState = memo<{ manageTemplatesUrl: string }>(({ manageTemplatesUrl }) => {
  const { t } = useTranslation(['conversations']);

  return (
    <StyledEmptyState data-lgg-id="templates-empty-state">
      <div className="iconContainer">
        <Icon type="whatsappTemplates" />
      </div>
      <span className="title">
        {t('conversations:messageInput.options.whatsapp.templatePicker.emptyState.title')}
      </span>
      <span className="message">
        <Trans
          i18nKey="conversations:messageInput.options.whatsapp.templatePicker.emptyState.message"
          components={{
            metaLink: (
              <Link
                data-lgg-id="manage-template-meta-link"
                to={{ pathname: manageTemplatesUrl }}
                target="_blank"
              />
            ),
          }}
        />
      </span>
    </StyledEmptyState>
  );
});

export const getWhatsappTemplateParamName = (key: string) =>
  key.replace('{{', '').replace('}}', '');

const positionalWhatsappParamRegex = /{{(\d+)}}/g;

type AddWhatsappTemplateOptionProps = {
  resourceData: {
    resourceId: string;
    phoneNumber?: string;
  };
  contactPhoneNumber: string;
  conversationId: string;
  renderTrigger: (props: { showModal; visible }) => ReactNode;
  handleFetchCreatedItem: (where: ConversationItemsWhereInput) => void;
};

export const AddWhatsappTemplateOption = ({
  resourceData: { resourceId, phoneNumber },
  contactPhoneNumber,
  conversationId,
  renderTrigger,
  handleFetchCreatedItem,
}: AddWhatsappTemplateOptionProps) => {
  const { t } = useTranslation(['conversations', 'common', 'errors']);
  const visibilityHandler = useVisible();
  const languageVisibilityHandler = useVisible();
  const breakpointUpMd = useBreakpoint(up('md'));
  const [keyword, setKeyword] = useState('');
  const [language, setLanguage] = useState('all');
  const debouncedKeyword = useDebouncedState(keyword);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [modalDisplay, setModalDisplay] = useState<'list' | 'preview'>('list');
  const [selectedTemplate, setSelectedTemplate] = useState<WhatsappTemplate | undefined>(
    undefined,
  );
  const showNotification = useShowNotification();

  const {
    handleLoadBottom,
    firstItemIndex,
    loadingMoreBottom,
    nodes,
    data,
    refetch,
    loading: loadingTemplates,
  } = useWhatsappTemplateQuery({
    resourceId,
    keyword: debouncedKeyword,
    language,
    skip: !visibilityHandler.visible,
  });

  const { data: resourceWhatsappData, loading: loadingResourceWhatsappData } = useQuery<
    Pick<Query, 'resourceWhatsapp'>,
    QueryResourceWhatsappArgs
  >(RESOURCE_WHATSAPP_QUERY, {
    variables: {
      id: Number(resourceId),
    },
  });

  const [templateParameters, setTemplateParameters] = useState<WhatsappTemplateData>(
    templateParametersDefaultValue,
  );

  const getComponentWithAppliedParameters = (
    rawMessage: string | undefined,
    parameters: WhatsappTemplateParameterInput[],
  ) => {
    if (!rawMessage) {
      return undefined;
    }

    let resolvedComponent = rawMessage;

    parameters.forEach((param) => {
      if (param.value) {
        resolvedComponent = resolvedComponent.replace(param.key, param.value?.text);
      }
    });

    return resolvedComponent;
  };

  const apolloClient = useApolloClient();

  const [whatsappResourceSetActive] = useWhatsappResourceSetActive();

  const handleUpdateWhatsappTemplatesSyncError = useCallback(
    (message: string) => {
      void whatsappResourceSetActive({
        variables: { resourceId: parseInt(resourceId), isActive: false },
        onCompleted: async (data) => {
          if (!data.resourceWhatsappSetActive.resource.id) {
            return;
          }

          void apolloClient.refetchQueries({
            include: [CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT],
          });

          showNotification({
            title: t('errors:notification.title'),
            message: t(
              'conversations:messageInput.options.whatsapp.templatePicker.error',
              {
                error: message,
              },
            ),
            type: 'error',
          });
        },
      });
    },
    [apolloClient, resourceId, showNotification, t, whatsappResourceSetActive],
  );

  const { handleSyncTemplates, isSyncingTemplates } = useHandleSyncWhatsappTemplates({
    resourceId: Number(resourceId),
    onCompleted: async (data) => {
      if (!data.updateWhatsappTemplatesSync) {
        return;
      }

      await refetch();
      showNotification({
        title: t(
          'conversations:messageInput.options.whatsapp.templatePicker.templatesSynced',
        ),
        type: 'success',
      });
    },
    onUpdateError: handleUpdateWhatsappTemplatesSyncError,
  });

  useEffect(() => {
    if (!selectedTemplate) {
      setTemplateParameters(templateParametersDefaultValue);
    }
  }, [selectedTemplate]);

  const hasValidLength = useCallback(() => {
    const headerMaxLength = 60;
    const expectedHeader = getComponentWithAppliedParameters(
      templateParameters.rawHeader,
      templateParameters.header,
    );

    const expectedBody = getComponentWithAppliedParameters(
      templateParameters.rawBody,
      templateParameters.body,
    );

    if (expectedHeader && expectedHeader.length > headerMaxLength) {
      showNotification({
        title: t(
          'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.validationMessages.headerMaxLength.title',
        ),
        message: t(
          'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.validationMessages.headerMaxLength.message',
          { limit: headerMaxLength },
        ),
        type: 'warning',
      });

      return false;
    }

    if (expectedBody) {
      const hasFooterOrHeader =
        templateParameters.rawFooter || templateParameters.rawHeader;
      const bodyLengthLimit = hasFooterOrHeader ? 1024 : 32768;

      if (expectedBody.length > bodyLengthLimit) {
        showNotification({
          title: t(
            'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.validationMessages.bodyMaxLength.title',
          ),
          message: t(
            'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.validationMessages.bodyMaxLength.message',
            { limit: bodyLengthLimit },
          ),
          type: 'warning',
        });

        return false;
      }
    }

    return true;
  }, [
    showNotification,
    t,
    templateParameters.body,
    templateParameters.header,
    templateParameters.rawBody,
    templateParameters.rawFooter,
    templateParameters.rawHeader,
  ]);

  const hasEmptyParameters = useCallback(() => {
    const hasEmptyFields = !(
      templateParameters.body.filter((value) => Boolean(value.value)).length ===
        templateParameters.bodyParamsCount &&
      templateParameters.header.filter((value) => Boolean(value.value)).length ===
        templateParameters.headerParamsCount
    );

    if (!hasEmptyFields) {
      return false;
    }

    const getParameterUpdatedValue = (parameter: WhatsappTemplateParameterInput) => ({
      ...parameter,
      hasError: !Boolean(parameter.value?.text),
    });

    const updatedBodyParams = templateParameters.body.map(getParameterUpdatedValue);
    const updatedHeaderParams = templateParameters.header.map(getParameterUpdatedValue);

    setTemplateParameters({
      ...templateParameters,
      body: updatedBodyParams,
      header: updatedHeaderParams,
    });

    showNotification({
      title: t(
        'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.validationMessages.missingParameters.title',
      ),
      message: t(
        'conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.validationMessages.missingParameters.message',
      ),
      type: 'warning',
    });

    return true;
  }, [showNotification, t, templateParameters]);

  const [sendWhatsappTemplate, { loading: isSendingWhatsappTemplate }] =
    useConversationSendWhatsappTemplateMutation();

  const templateCount = useMemo(
    () => (
      <TemplateCount data-lgg-id="whatsapp-template-count">
        {t('common:template', {
          count: data?.whatsappTemplates.totalCount ?? 0,
        })}
      </TemplateCount>
    ),
    [data?.whatsappTemplates.totalCount, t],
  );

  const hasParameters = useMemo(
    () =>
      selectedTemplate &&
      (templateParameters.bodyParamsCount || templateParameters.headerParamsCount),
    [
      selectedTemplate,
      templateParameters.bodyParamsCount,
      templateParameters.headerParamsCount,
    ],
  );

  const handleModalClose = useCallback(() => {
    visibilityHandler.close();
    setModalDisplay('list');
    setSelectedTemplate(undefined);
  }, [visibilityHandler]);

  const handleSendTemplate = useCallback(
    async (options: { template: WhatsappTemplate }) => {
      const { template } = options;

      if (!hasValidLength() || hasEmptyParameters()) {
        return;
      }

      const getFormattedVariables = (parameters: WhatsappTemplateParameterInput[]) => {
        return parameters
          .filter(({ value }) => value !== undefined)
          .map((parameter) => {
            const { key, value } = parameter;

            const baseParameter = {
              type: 'text',
              text: value?.text,
            };

            if (key.match(positionalWhatsappParamRegex)) {
              return baseParameter;
            }

            return {
              ...baseParameter,
              parameterName: getWhatsappTemplateParamName(key),
            };
          }) as WhatsAppTemplateTextParameter[];
      };

      const headerComponent = template.components.find(
        (t) =>
          t.__typename === 'WhatsappTemplateComponentHeader' ||
          t.__typename === 'WhatsappTemplateComponentMediaHeader',
      );

      const headerVariables = match(headerComponent)
        .with(
          {
            __typename: 'WhatsappTemplateComponentHeader',
          },
          () => getFormattedVariables(templateParameters.header),
        )
        .with({ __typename: 'WhatsappTemplateComponentMediaHeader' }, (template) => {
          const mediaLink = template.example.headerHandle?.[0];

          return match(template.format)
            .with('IMAGE', () => [
              {
                type: 'IMAGE',
                image: {
                  link: mediaLink,
                },
              },
            ])
            .with('VIDEO', () => [
              {
                type: 'VIDEO',
                video: {
                  link: mediaLink,
                },
              },
            ])
            .with('DOCUMENT', () => [
              {
                type: 'DOCUMENT',
                document: {
                  link: mediaLink,
                },
              },
            ])
            .otherwise(() => []);
        })
        .otherwise(() => []);

      await sendWhatsappTemplate({
        variables: {
          resourceId,
          templateId: template.id,
          bodyVariables: getFormattedVariables(templateParameters.body),
          headerVariables: headerVariables,
          button: templateParameters.button ?? null,
          conversationId,
          contactPhoneNumber,
        },
        onCompleted: ({ conversationSendWhatsappTemplate: data }) => {
          if (!data.interaction?.id) {
            return;
          }

          void handleFetchCreatedItem({
            contactInteractionId: {
              eq: Number(data.interaction.id),
            },
          });

          publishEvent('CONVERSATION_UPDATED', { id: conversationId });

          handleModalClose();
        },
        onError: (error) => {
          const isLowResourceBalanceError = error.graphQLErrors?.some(
            (e) =>
              e.extensions?.detail.http?.response.data?.meta?.errorMeta?.code ===
              'LOW_RESOURCE_BALANCE',
          );

          if (isLowResourceBalanceError) {
            showNotification({
              title: (
                <Trans
                  i18nKey="conversations:messageInput.options.whatsapp.templatePicker.lowResourceBalance.title"
                  values={{ phoneNumber }}
                />
              ),
              message: t(
                'conversations:messageInput.options.whatsapp.templatePicker.lowResourceBalance.details',
              ),
              type: 'warning',
              actionElements: (
                <ActionItems>
                  <a
                    data-lgg-id="no-balance-notification-left-action"
                    href="https://app.360dialog.io/login/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(
                      'conversations:messageInput.options.whatsapp.templatePicker.lowResourceBalance.leftAction',
                    )}
                  </a>
                  <span />
                  <a
                    data-lgg-id="no-balance-notification-right-action"
                    href="https://docs.360dialog.com/docs/client-hub/funds"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(
                      'conversations:messageInput.options.whatsapp.templatePicker.lowResourceBalance.rightAction',
                    )}
                  </a>
                </ActionItems>
              ),
            });
            handleModalClose();
          }
        },
      });
    },
    [
      hasValidLength,
      hasEmptyParameters,
      templateParameters,
      sendWhatsappTemplate,
      resourceId,
      conversationId,
      contactPhoneNumber,
      handleFetchCreatedItem,
      handleModalClose,
      showNotification,
      phoneNumber,
      t,
    ],
  );

  const onBack = useCallback(() => {
    setModalDisplay('list');
    setSelectedTemplate(undefined);
  }, []);

  const footerActionButtons = useMemo(
    () => (
      <ModalFooterContainer>
        {modalDisplay === 'preview' && (
          <FooterActionButton
            variant="tertiary"
            onClick={() => {
              onBack();
            }}
          >
            {t('common:back')}
          </FooterActionButton>
        )}
        <FooterActionButton
          loading={isSendingWhatsappTemplate}
          data-lgg-id="whatsapp-template-picker-submit-button"
          variant={selectedTemplate ? 'primary' : 'tertiary'}
          onClick={() => {
            if (!selectedTemplate) {
              return;
            }
            // All cases handled for fixed values
            // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
            void match(modalDisplay)
              .with('list', () => setModalDisplay('preview'))
              .with('preview', () => handleSendTemplate({ template: selectedTemplate }))
              .exhaustive();
          }}
        >
          {modalDisplay === 'list' ? t('common:next') : t('common:send')}
        </FooterActionButton>
      </ModalFooterContainer>
    ),
    [
      t,
      isSendingWhatsappTemplate,
      selectedTemplate,
      modalDisplay,
      onBack,
      handleSendTemplate,
    ],
  );

  const templateSearchInput = useMemo(
    () => (
      <TemplateSearchInput
        data-lgg-id="conversation-keyword-filter-input"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        placeholder={t(
          'conversations:messageInput.options.whatsapp.templatePicker.searchTemplate',
        )}
        leadingIcon={breakpointUpMd ? undefined : 'magnifyingGlass'}
      />
    ),
    [keyword, breakpointUpMd, t],
  );

  const languageDropdownPicker = useMemo(() => {
    const options = [
      {
        label: breakpointUpMd ? t('common:all') : t('common:languages.allLanguages'),
        buttonLabel: !breakpointUpMd && t('common:languages.title'),
        value: 'all',
        'data-lgg-id': 'whatsapp-template-language-dropdown-all-option',
      },
      {
        label: t('common:languages.english'),
        value: 'en',
        'data-lgg-id': 'whatsapp-template-language-dropdown-en-option',
      },
      {
        label: t('common:languages.spanish'),
        value: 'es',
        'data-lgg-id': 'whatsapp-template-language-dropdown-es-option',
      },
    ].map((option) => ({
      ...option,
      onClick: () => {
        setLanguage(option.value);
        languageVisibilityHandler.close();
      },
    }));

    const selectedOption = options.find((option) => option.value === language);

    const dropdownButton = (
      <LanguageDropdownButton
        variant="defaultWhite"
        size="small"
        data-lgg-id="whatsapp-template-language-dropdown-button"
        isActive={languageVisibilityHandler.visible}
        onClick={() =>
          languageVisibilityHandler.setVisible(!languageVisibilityHandler.visible)
        }
      >
        {selectedOption?.buttonLabel || selectedOption?.label}
      </LanguageDropdownButton>
    );

    return breakpointUpMd ? (
      <LggSelectableOptionsDropdownButtonWithCustomTrigger
        options={options}
        children={<span>{dropdownButton}</span>}
        selectedValue={language}
        size="small"
        variant="default"
        visibilityHandler={languageVisibilityHandler}
      />
    ) : (
      <>
        {dropdownButton}
        <SelectableOptionsDrawer
          visible={languageVisibilityHandler.visible}
          title={t('common:languages.mobileTitle')}
          onClose={languageVisibilityHandler.close}
          options={options}
          selectedValue={language}
        />
      </>
    );
  }, [breakpointUpMd, language, languageVisibilityHandler, t]);

  const manageTemplatesUrl = resourceWhatsappData
    ? getManageTemplatesUrl(resourceWhatsappData.resourceWhatsapp.waba)
    : '';

  const whatsappTemplateList = useMemo(() => {
    return match({ loadingTemplates, nodes })
      .with({ loadingTemplates: true, nodes: [] }, () => (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      ))
      .with({ nodes: [], loadingTemplates: false }, () => (
        <EmptyState manageTemplatesUrl={manageTemplatesUrl} />
      ))
      .with({ nodes: P.when((nodes) => nodes.length > 0) }, ({ nodes }) => (
        <WhatsappTemplateListContainer>
          <Virtuoso
            ref={virtuosoRef}
            data-lgg-id="whatsapp-template-list"
            data={nodes}
            initialItemCount={0}
            initialTopMostItemIndex={0}
            endReached={async () => {
              await handleLoadBottom();
            }}
            firstItemIndex={firstItemIndex}
            components={{
              Footer: () =>
                loadingMoreBottom ? (
                  <ListLoadingItem visible={loadingMoreBottom} />
                ) : (
                  <TemplateListFooter />
                ),
            }}
            itemContent={(_, template) => (
              <WhatsappTemplateListItem
                key={template.id}
                template={template}
                selected={!!selectedTemplate?.id && selectedTemplate.id === template.id}
                onClick={() => {
                  setSelectedTemplate(template);
                }}
              />
            )}
          />
        </WhatsappTemplateListContainer>
      ))
      .run();
  }, [
    manageTemplatesUrl,
    firstItemIndex,
    handleLoadBottom,
    loadingMoreBottom,
    loadingTemplates,
    nodes,
    selectedTemplate?.id,
  ]);

  const syncTemplatesButton = useMemo(() => {
    return (
      <WhatsappSyncButton
        testId="whatsapp-template-sync-button"
        isSyncing={isSyncingTemplates}
        onClick={() => {
          void handleSyncTemplates();
        }}
      />
    );
  }, [handleSyncTemplates, isSyncingTemplates]);

  const templatePreview = useMemo(
    () =>
      selectedTemplate ? (
        <TemplatePreviewContainer>
          <TemplateScrollContainer>
            <WhatsappTemplatePreviewItem
              template={selectedTemplate}
              occurredAt={new Date().toISOString()}
              parameters={templateParameters}
              setParameters={setTemplateParameters}
            />
          </TemplateScrollContainer>
          {hasParameters ? (
            <TemplatePreviewTipContainer>
              <Trans i18nKey="conversations:messageInput.modes.reply.messages.whatsapp.whatsappTemplate.editTemplateTip" />
            </TemplatePreviewTipContainer>
          ) : null}
        </TemplatePreviewContainer>
      ) : null,
    [hasParameters, selectedTemplate, templateParameters],
  );

  const manageTemplatesButton = useMemo(() => {
    return (
      <ManageWhatsappTemplatesButton
        isLoading={loadingResourceWhatsappData}
        wabaId={resourceWhatsappData?.resourceWhatsapp.waba}
      />
    );
  }, [loadingResourceWhatsappData, resourceWhatsappData?.resourceWhatsapp.waba]);

  return (
    <>
      {renderTrigger({
        visible: visibilityHandler.visible,
        showModal: visibilityHandler.show,
      })}
      {visibilityHandler.visible && (
        <>
          {breakpointUpMd ? (
            <AddWhatsappTemplateModal
              onClose={handleModalClose}
              visible
              width={740}
              maskClosable={false}
              keyboard={false}
              destroyOnClose
              // TODO: Check error with virtuoso and modal animation causing the list to act buggy
              transitionName=""
              maskTransitionName=""
              wrapProps={{
                'data-lgg-id': 'whatsapp-template-picker-modal',
              }}
              // TODO: Fix index error
              zIndex={1000 + 1}
            >
              {match({ viewState: modalDisplay, selectedTemplate })
                .with({ viewState: 'list' }, () => (
                  <>
                    <ModalHeaderContainer>
                      <ModalHeaderTitle>
                        {t(
                          'conversations:messageInput.options.whatsapp.templatePicker.title',
                        )}
                      </ModalHeaderTitle>
                      {syncTemplatesButton}
                      {manageTemplatesButton}
                    </ModalHeaderContainer>
                    <ModalBodyContainer>
                      <ModalBodyTopContent>
                        {templateCount}
                        <FlexColumn>
                          <FilterLabel>{t('common:language')}</FilterLabel>
                          {languageDropdownPicker}
                        </FlexColumn>
                        <SearchInputContainer>
                          <FilterLabel>{t('common:search')}</FilterLabel>
                          {templateSearchInput}
                        </SearchInputContainer>
                      </ModalBodyTopContent>
                      {whatsappTemplateList}
                    </ModalBodyContainer>
                    {footerActionButtons}
                  </>
                ))
                .with({ viewState: 'preview', selectedTemplate: P.nonNullable }, () => (
                  <>
                    <ModalHeaderContainer>
                      <ModalHeaderTitle>
                        {t(
                          'conversations:messageInput.options.whatsapp.templatePicker.preview.title',
                        )}
                      </ModalHeaderTitle>
                    </ModalHeaderContainer>
                    {templatePreview}
                    {footerActionButtons}
                  </>
                ))
                .run()}
            </AddWhatsappTemplateModal>
          ) : (
            <TemplatePickerBottomDrawer
              fullHeight
              addScrollbarToBody={false}
              onClose={handleModalClose}
              leading={
                modalDisplay === 'preview' ? (
                  <DrawerHeaderBackIcon onClick={onBack} />
                ) : null
              }
              title={
                modalDisplay === 'list'
                  ? t('conversations:messageInput.options.whatsapp.templatePicker.title')
                  : t(
                      'conversations:messageInput.options.whatsapp.templatePicker.preview.titleMobile',
                    )
              }
              visible={visibilityHandler.visible}
              footer={footerActionButtons}
            >
              {match({ viewState: modalDisplay, selectedTemplate })
                .with({ viewState: 'list' }, () => (
                  <>
                    <HeaderTopContainer>
                      {templateCount}
                      {syncTemplatesButton}
                      {languageDropdownPicker}
                    </HeaderTopContainer>
                    <HeaderBottomContainer>{templateSearchInput}</HeaderBottomContainer>
                    {whatsappTemplateList}
                  </>
                ))
                .with(
                  { viewState: 'preview', selectedTemplate: P.nonNullable },
                  () => templatePreview,
                )
                .run()}
            </TemplatePickerBottomDrawer>
          )}
        </>
      )}
    </>
  );
};
