import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { PhoneNumber } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { useOpenCallContactModal } from 'src/components/pages/conversations/components/open-legacy-call-modal-icon';
import { useVisible } from 'src/hooks/use-visible';
import { copyTextToClipboard } from 'src/utils/copy-to-clipboard-helpers';

const StyledButton = styled(ButtonV2)`
  padding: 0;
  display: inline-block;
  background: transparent !important;
  height: unset;
`;

const BlueText = styled.span`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.flint};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.25;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: ${({ theme }) => theme.colors.globalBlue};
  }
`;

const HiddenText = styled.span`
  visibility: hidden;
  width: 0px;
  height: 0px;
  display: inline-block;
`;

type ContactCopyMenuProps = {
  config:
    | {
        phone?: PhoneNumber | null;
      }
    | {
        email?: string | null;
      };
  contactId: number;
};

export const ContactCopyMenu = ({ contactId, config, ...rest }: ContactCopyMenuProps) => {
  const { t } = useTranslation(['contacts']);
  const visibilityHandler = useVisible();
  const { show, close } = visibilityHandler;
  const openCallContactModal = useOpenCallContactModal();

  const callContact = useCallback(() => {
    void openCallContactModal(contactId);
    close();
  }, [contactId, close, openCallContactModal]);

  const sendEmail = useCallback(() => {
    if ('email' in config) {
      window.location.href = `mailto:${config.email}`;
      close();
    }
  }, [config, close]);

  const { options, text, action, type } = match(config)
    .with({ phone: { national: P.nonNullable, e164: P.nonNullable } }, ({ phone }) => ({
      options: [
        {
          label: t('contacts:copyMenu.copyPhone'),
          icon: 'copy',
          'data-lgg-id': 'copy-menu-contact-phone',
          onClick: () => {
            copyTextToClipboard(phone?.e164);
            close();
          },
        },
        {
          label: t('contacts:copyMenu.callContact'),
          icon: 'call',
          'data-lgg-id': 'copy-menu-contact-phone-icon',
          onClick: callContact,
        },
      ],
      text: phone?.national,
      action: callContact,
      type: 'phone',
    }))
    .with({ email: P.nonNullable }, ({ email }) => ({
      options: [
        {
          label: t('contacts:copyMenu.copyEmail'),
          icon: 'copy',
          'data-lgg-id': 'copy-menu-contact-email',
          onClick: () => {
            copyTextToClipboard(email);
            close();
          },
        },
        {
          label: t('contacts:copyMenu.sendEmail'),
          icon: 'email',
          'data-lgg-id': 'copy-menu-contact-email-icon',
          onClick: sendEmail,
        },
      ],
      text: email,
      action: sendEmail,
      type: 'email',
    }))
    .otherwise(() => ({ options: null, text: null, action: null, type: null }));

  if (!text) {
    return null;
  }

  return (
    <>
      <LggOptionsDropdownButtonWithCustomTrigger
        customDropdownProps={{
          trigger: ['click'],
          align: {
            offset: [180, 0],
          },
          placement: 'bottomRight',
        }}
        visibilityHandler={visibilityHandler}
        options={options}
        customTrigger={<HiddenText>Hidden Element</HiddenText>}
      />
      <StyledButton
        data-lgg-id={`contact-copy-menu-button-${type}`}
        variant="default"
        size="regular"
        onClick={action}
        onContextMenu={(e) => {
          e.preventDefault();
          show();
        }}
      >
        <BlueText {...rest}>{text}</BlueText>
      </StyledButton>
    </>
  );
};
