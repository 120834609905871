import { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { z } from 'zod';
import {
  Mutation,
  MutationUpdateUserArgs,
  User,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useProfileSettings } from 'src/components/domain/users/components/profile-settings-modal/context/profile-settings-provider';
import { UPDATE_USER_MUTATION } from 'src/components/domain/users/components/profile-settings-modal/operations';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';

type PersonalInformationFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneExt: string;
  phone2: string;
  phone2Ext: string;
};

const parseDefaultValues = (currentUser: User) =>
  ({
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser?.email || '',
    phone: currentUser?.primaryPhone?.national || '',
    phoneExt: currentUser?.phoneExt || '',
    phone2: currentUser?.alternativePhone?.national || '',
    phone2Ext: currentUser?.phone2Ext || '',
  } as PersonalInformationFormValues);

export const validatePhone = (value: string) => {
  const phoneNumber = parsePhoneNumber(value, { regionCode: 'US' });

  return phoneNumber.valid;
};
const validatePhoneExtension = (value: string) => /^[0-9#*w]+$/i.test(value);

const isOptionalFieldValid = (
  value: string | undefined | null,
  validator: (value: string) => boolean,
) => {
  if (!value) return true;

  return validator(value);
};

export const usePersonalInformationForm = (currentUser: User) => {
  const { showCancelConfirm, visibilityHandler } = useProfileSettings();

  const [updateUser] = useMutation<Pick<Mutation, 'updateUser'>, MutationUpdateUserArgs>(
    UPDATE_USER_MUTATION,
  );

  const { t } = useTranslation(['user']);

  const personalInformationSchema = useMemo(
    () =>
      z.object({
        firstName: z
          .string({
            required_error: t(
              'user:profileSettings.sections.personalInformation.fields.firstName.error.required',
            ),
          })
          .min(1, {
            message: t(
              'user:profileSettings.sections.personalInformation.fields.firstName.error.invalid',
            ),
          }),
        lastName: z
          .string({
            required_error: t(
              'user:profileSettings.sections.personalInformation.fields.lastName.error.required',
            ),
          })
          .min(1, {
            message: t(
              'user:profileSettings.sections.personalInformation.fields.lastName.error.invalid',
            ),
          }),
        email: z
          .string({
            required_error: t(
              'user:profileSettings.sections.personalInformation.fields.email.error.required',
            ),
          })
          .email({
            message: t(
              'user:profileSettings.sections.personalInformation.fields.email.error.invalid',
            ),
          }),
        phone: z
          .string()
          .nullish()
          .refine((value) => isOptionalFieldValid(value, validatePhone), {
            message: t(
              'user:profileSettings.sections.personalInformation.fields.phone.error.invalid',
            ),
          }),
        phoneExt: z
          .string()
          .nullish()
          .refine((value) => isOptionalFieldValid(value, validatePhoneExtension), {
            message: t(
              'user:profileSettings.sections.personalInformation.fields.phoneExt.error.invalid',
            ),
          }),
        phone2: z
          .string()
          .nullish()
          .refine((value) => isOptionalFieldValid(value, validatePhone), {
            message: t(
              'user:profileSettings.sections.personalInformation.fields.alternativePhone.error.invalid',
            ),
          }),
        phone2Ext: z
          .string()
          .nullish()
          .refine((value) => isOptionalFieldValid(value, validatePhoneExtension), {
            message: t(
              'user:profileSettings.sections.personalInformation.fields.alternativePhoneExt.error.invalid',
            ),
          }),
      }),
    [t],
  );

  const form = useForm<PersonalInformationFormValues>({
    resolver: zodResolver(personalInformationSchema),
    defaultValues: parseDefaultValues(currentUser),
  });

  const {
    formState: { isDirty },
    reset,
  } = form;

  const showNotification = useShowNotification();

  const onSubmit: SubmitHandler<PersonalInformationFormValues> = useCallback(
    (data) => {
      void updateUser({
        variables: {
          input: data,
        },
        onCompleted: () => {
          visibilityHandler.close();
          showNotification({
            type: 'success',
            title: t('user:profileSettings.sections.personalInformation.toasts.success'),
          });
        },
        onError: () => {
          showNotification({
            type: 'error',
            title: t('user:profileSettings.sections.personalInformation.toasts.error'),
          });
        },
      });
    },
    [t, updateUser, visibilityHandler, showNotification],
  );

  const onReset = () => showCancelConfirm(isDirty, reset);

  return {
    form,
    onReset,
    onSubmit,
  };
};
