import React, { memo, useEffect, useState } from 'react';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Dayjs, dayjs } from '@lgg/isomorphic/dayjs';
import Calendar from 'src/components/general/calendar/calendar';
import { Icon } from 'src/components/general/icon';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';

const CalendarHeader = styled.div`
  min-height: 65px;
  background: ${({ theme }) => theme.colors.porcelain};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
`;

const CustomDatePickerCalendar = styled(Calendar)`
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  font-family: ${({ theme }) => theme.font.regular};
  margin: 0;
  min-width: 308px;

  ${up('md')} {
    margin: 0 0 20px;
    min-width: unset;
  }

  &.small {
    max-width: 226px;

    ${CalendarHeader} {
      padding: 13px 15px;
      min-height: 65px;

      ${up('md')} {
        min-height: 40px;
      }
    }

    .ant-picker-panel {
      .ant-picker-body {
        .ant-picker-content {
          thead tr th {
            height: 44px;
            width: 30px;

            ${up('md')} {
              height: 33px;
            }

            &:first-child {
              padding: 0 0 0 10px;
            }

            &:last-child {
              padding: 0 10px 0 0;
            }

            &:first-child,
            &:last-child {
              width: 38px;
              text-align: center;
            }
          }

          tbody tr {
            td {
              &:first-child .date-container {
                margin-right: 2px;
                margin-top: 4px;
              }

              &:last-child .date-container {
                margin-left: 2px;
                margin-top: 4px;
              }

              .date-container {
                height: 30px;
                margin: 4px auto 0;
                width: 30px;

                .overlay {
                  width: 30px;
                  height: 30px;
                }

                ${up('md')} {
                  height: 26px;
                  width: 26px;

                  .overlay {
                    height: 26px;
                    width: 26px;
                  }
                }
              }
            }

            &:first-child {
              td {
                .date-container {
                  margin: 10px auto 0;
                }

                &:first-child {
                  .date-container {
                    margin: 10px 2px 0 auto;
                  }
                }

                &:last-child {
                  .date-container {
                    margin: 10px 0px 0px 0px;
                  }
                }
              }
            }

            &:last-child {
              td {
                .date-container {
                  margin: 4px auto 10px;
                }

                &:first-child {
                  .date-container {
                    margin: 4px 2px 10px auto;
                  }
                }

                &:last-child {
                  .date-container {
                    margin: 4px 10px 10px 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & .ant-picker-month-panel {
    min-height: 250px;

    td.ant-picker-cell {
      height: 100%;

      .ant-picker-cell-inner {
        width: 50px;
        height: 30px;
        margin: 13px auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-family: ${({ theme }) => theme.font.regular};
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17px;
        letter-spacing: -0.14px;
        text-align: center;
        color: ${({ theme }) => theme.colors.smalt};
      }

      &.ant-picker-cell-disabled {
        .ant-picker-cell-inner {
          color: ${({ theme }) => theme.colors.casper};
          background: ${({ theme }) => theme.colors.white};
        }
      }

      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: none;

          .ant-picker-calendar-date-value {
            color: ${({ theme }) => theme.colors.gogo};
            z-index: 2;
          }

          .ant-picker-calendar-date-content {
            width: 100%;
            height: 100%;
            position: absolute;
            background: ${({ theme }) => theme.colors.gogo};
            border-radius: 4px;
            opacity: 0.17;
            z-index: 1;
          }
        }
      }
    }

    tr {
      &:first-child {
        .ant-picker-cell-inner {
          margin-top: 26px;
        }
      }

      &:last-child {
        .ant-picker-cell-inner {
          margin-bottom: 26px;
        }
      }
    }
  }

  & .ant-picker-panel {
    display: flex;
    align-items: center;

    .ant-picker-body {
      padding: 0;

      .ant-picker-content {
        width: 100%;
        height: max-content;

        thead tr th,
        tbody tr td {
          width: max-content;
          height: max-content;
          text-align: center;
          padding: 0;
          line-height: 15px;
        }

        thead tr {
          th {
            height: 44px;
            width: 42px;
          }
        }

        thead tr {
          th:last-child,
          th:first-child {
            width: 100%;
          }
        }

        thead tr {
          th:last-child {
            padding-left: 13.5px;
            text-align: left;
          }
        }

        thead tr {
          th:first-child {
            padding-right: 13.5px;
            text-align: right;
          }
        }

        tbody tr {
          td:first-child {
            .date-container {
              margin: auto;
              margin-right: 6px;
            }
          }
        }

        tbody tr {
          td:last-child {
            .date-container {
              margin: auto;
              margin-left: 6px;
            }
          }
        }

        tbody tr:first-child {
          td {
            .date-container {
              margin: 13.5px auto 3.5px 6px;
            }
          }
        }

        tbody tr:last-child {
          td {
            .date-container {
              margin-top: 3.5px;
              margin-bottom: 14px;
            }
          }
        }

        tbody tr:first-child {
          td:first-child {
            .date-container {
              margin: 13.5px 6px 3.5px auto;
            }
          }
        }

        thead {
          border-bottom: 1px solid ${({ theme }) => theme.colors.koala};

          tr th {
            font-family: ${({ theme }) => theme.font.medium};
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.12px;
            text-align: center;
            color: ${({ theme }) => theme.colors.carbon};
            line-height: 14px;
          }
        }

        .date-container {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          margin: 3.5px 6px;

          .date {
            font-size: 13px;
            letter-spacing: -0.13px;
            z-index: 2;
          }

          .overlay {
            z-index: 1;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 4px;
          }

          &:hover {
            .overlay {
              background: ${({ theme }) => theme.colors.porcelain};
            }
          }
        }

        .ant-picker-cell {
          .date-container.selected {
            .date {
              color: ${({ theme }) => theme.colors.gogo};
            }
            .overlay {
              opacity: 0.2;
              background: ${({ theme }) => theme.colors.gogo};
            }
          }
        }

        .ant-picker-cell-in-view {
          .date-container {
            color: ${({ theme }) => theme.colors.smalt};
          }
        }

        .ant-picker-cell-disabled {
          &::before {
            background: none;
          }

          .date-container {
            color: ${({ theme }) => theme.colors.casper};
          }
        }

        .ant-picker-cell:not(.ant-picker-cell-in-view) {
          .date-container {
            color: ${({ theme }) => theme.colors.casper};
          }
        }
      }
    }
  }
`;

const CalendarHeaderLabel = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.flint};
  cursor: pointer;
  text-transform: capitalize;
`;

const CalendarHeaderArrowIcon = styled(Icon)`
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

type Props = {
  setDatePickerDate: (date: Date) => void;
  datePickerDate?: Date;
  onSelect: (date: Date, calendarMode: CalendarMode) => void;
  variant?: 'small' | 'regular';
  className?: string;
  isPanelVisible?: boolean;
  updateOnPageChange?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  pickerBaseValue?: Date;
};

export const CalendarDatePicker = memo<Props>(
  ({
    setDatePickerDate,
    datePickerDate,
    onSelect,
    variant = 'regular',
    className = '',
    isPanelVisible = false,
    updateOnPageChange = true,
    disabledDate,
    pickerBaseValue,
  }) => {
    const [customDatePickerMode, setCustomDatePickerMode] =
      useState<CalendarMode>('month');
    const [calendarCurrentDate, setCalendarCurrentDate] = useState<Date | undefined>(
      datePickerDate,
    );
    const { formatDate } = useFormatDate();
    const { subYears, subMonths, addYears, addMonths, isSameDay } = useDateHelpers();

    useEffect(() => {
      if (isPanelVisible) {
        setCustomDatePickerMode('month');
      }
    }, [isPanelVisible]);

    return (
      <CustomDatePickerCalendar
        data-lgg-id="custom-date-picker-calendar"
        disabledDate={disabledDate}
        mode={customDatePickerMode}
        className={`${variant} ${className}`}
        fullscreen={false}
        value={dayjs(calendarCurrentDate ?? pickerBaseValue ?? new Date())}
        onSelect={(date) => {
          onSelect(date.toDate(), customDatePickerMode);

          if (customDatePickerMode === 'year') {
            setCalendarCurrentDate(date.toDate());
            setCustomDatePickerMode('month');
          }
        }}
        dateFullCellRender={(date) => {
          const formattedDate = formatDate(date.toDate(), 'D', false);

          return (
            <div
              className={c({
                'date-container': true,
                selected: datePickerDate
                  ? isSameDay(datePickerDate, date.toDate())
                  : false,
              })}
              data-lgg-id={`custom-date-picker-date-${formattedDate}`}
            >
              <span className="date">{formattedDate}</span>
              <span className="overlay" />
            </div>
          );
        }}
        headerRender={({ value }) => (
          <CalendarHeader>
            <CalendarHeaderArrowIcon
              type="arrowBackNoPadding"
              lggTestId="custom-date-picker-previous-month-button"
              onClick={() => {
                const updatedValue =
                  customDatePickerMode === 'year'
                    ? subYears(value.toDate(), 1)
                    : subMonths(value.toDate(), 1);

                setCalendarCurrentDate(updatedValue);

                if (updateOnPageChange) {
                  setDatePickerDate(updatedValue);
                }
              }}
            />
            <CalendarHeaderLabel
              onClick={() => {
                setCustomDatePickerMode(
                  customDatePickerMode === 'month' ? 'year' : 'month',
                );
              }}
            >
              {formatDate(
                value.toDate(),
                customDatePickerMode === 'month' ? 'MMM YYYY' : 'YYYY',
                false,
              )}
            </CalendarHeaderLabel>
            <CalendarHeaderArrowIcon
              type="arrowNextNoPadding"
              lggTestId="custom-date-picker-next-month-button"
              onClick={() => {
                const updatedValue =
                  customDatePickerMode === 'year'
                    ? addYears(value.toDate(), 1)
                    : addMonths(value.toDate(), 1);

                setCalendarCurrentDate(updatedValue);

                if (updateOnPageChange) {
                  setDatePickerDate(updatedValue);
                }
              }}
            />
          </CalendarHeader>
        )}
      />
    );
  },
);
